import { Controller } from "@hotwired/stimulus";
import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import { Color } from "@tiptap/extension-color"
import ListItem from "@tiptap/extension-list-item"
import TextStyle from "@tiptap/extension-text-style"
import TextUnderline from "@tiptap/extension-underline"
import Gapcursor from '@tiptap/extension-gapcursor'
import Dropcursor from '@tiptap/extension-dropcursor'
import ImageNode from '@tiptap/extension-image'
import ImageNodeExtension from '../../config/tiptapImageNodeExtension'
import LinkItem from '@tiptap/extension-link'
import axios, * as others from 'axios';

export default class extends Controller {
  static targets = [
    'formField',
    'btnB',
    'btnI',
    'btnU',
    'btnS',
    'btnLink',
    'btnCode',
    'btnCodeBlock',
    'btnBlockquote',
    'btnH1',
    'btnH2',
    'btnH3',
    'btnP',
    'btnOl',
    'btnUl',
    'btnColor',
    'btnReset',
    'btnClearNodes',
    'btnUnsetAllMarks',
    'btnFullScreen',
    'contentWrapper',
  ]

  static values = {
    fullScreen: Boolean
  }

  initialize() {}

  connect() {
    this.wrapperTarget = this.element.querySelector(".tiptap__editor")
    this.tiptapContentEl = this.element.querySelector(".tiptap__content")
    this.uid = `tiptap_uid_${Math.floor(Math.random() * 100)}`
    this.element.setAttribute('id', this.uid)
    this.axios = axios
    const t = this

    this.editor = new Editor({
      element: this.tiptapContentEl,
      extensions: [
        Gapcursor,
        Color.configure({ types: [TextStyle.name, ListItem.name] }),
        TextStyle.configure({ types: [ListItem.name] }),
        StarterKit.configure({
          bulletList: {
            keepMarks: true,
            keepAttributes: false,
          },
          orderedList: {
            keepMarks: true,
            keepAttributes: true,
          },
        }),
        ImageNode,
        ImageNodeExtension,
        TextUnderline,
        Dropcursor.configure({
          color: '#ff0000',
        }),
        LinkItem.configure({ 
          autolink: false,
          HTMLAttributes: {
            rel: '',
          },
        })
      ],
      editorProps: {
        attributes: {
          class: 'rte',
        },
        handleDrop: function (view, event, slice, moved) {
          if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) { // if dropping external files
            let file = event.dataTransfer.files[0]; // the dropped file
            let filesize = ((file.size / 1024) / 1024).toFixed(4); // get the filesize in MB
            if ((file.type === "image/jpeg" || file.type === "image/png") && filesize < 5) { // check valid image type under 5MB
              // check the dimensions
              let _URL = window.URL || window.webkitURL;
              let img = new Image(); /* global Image */
              img.src = _URL.createObjectURL(file);
              img.onload = function () {
                if (this.width > 5000 || this.height > 5000) {
                  window.alert("Your images need to be less than 5000 pixels in height and width."); // display alert
                } else {
                  // valid image so upload to server
                  // uploadImage will be your function to upload the image to the server or s3 bucket somewhere
                  t.uploadImage(file).then(function ({data: { url }}) { // response is the image url for where it has been saved
                    // pre-load the image before responding so loading indicators can stay
                    // and swaps out smoothly when image is ready
                    let image = new Image();
                    image.src = url;
                    image.onload = function () {
                      // place the now uploaded image in the editor where it was dropped
                      const { schema } = view.state;
                      const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
                      const node = schema.nodes.image.create({ src: url }); // creates the image element
                      const transaction = view.state.tr.insert(coordinates.pos, node); // places it in the correct position
                      return view.dispatch(transaction);
                    }
                  }).catch(function (error) {
                    if (error) {
                      window.alert("There was a problem uploading your image, please try again.");
                    }
                  });
                }
              };
            } else {
              window.alert("Images need to be in jpg or png format and less than 5mb in size.");
            }
            return true; // handled
          }
          return false; // not handled use default behaviour
        }
      },
      content: t.initContent(),
      type: 'HTML',
      autofocus: true,
      editable: true,
      injectCSS: false,
      hideHTMLTags: true,
    })
    this.editor.on('transaction', ({ editor }) => {
      if (t.hasBtnBTarget) t.switchClass(t.btnBTarget, editor.isActive('bold'))
      if (t.hasBtnLinkTarget) t.switchClass(t.btnLinkTarget, editor.isActive('link'))
      if (t.hasBtnITarget) t.switchClass(t.btnITarget, editor.isActive('italic'))
      if (t.hasBtnUTarget) t.switchClass(t.btnUTarget, editor.isActive('underline'))
      if (t.hasBtnSTarget) t.switchClass(t.btnSTarget, editor.isActive('strike'))
      if (t.hasBtnCodeTarget) t.switchClass(t.btnCodeTarget, editor.isActive('code'))
      if (t.hasBtnCodeBlockTarget) t.switchClass(t.btnCodeBlockTarget, editor.isActive('codeblock'))
      if (t.hasBtnBlockquoteTarget) t.switchClass(t.btnBlockquoteTarget, editor.isActive('blockquote'))
      if (t.hasBtnH1Target) t.switchClass(t.btnH1Target, editor.isActive('heading', { level: 1 }))
      if (t.hasBtnH2Target) t.switchClass(t.btnH2Target, editor.isActive('heading', { level: 2 }))
      if (t.hasBtnH3Target) t.switchClass(t.btnH3Target, editor.isActive('heading', { level: 3 }))
      if (t.hasBtnH4Target) t.switchClass(t.btnH4Target, editor.isActive('heading', { level: 4 }))
      if (t.hasBtnH5Target) t.switchClass(t.btnH5Target, editor.isActive('heading', { level: 5 }))
      if (t.hasBtnPTarget) t.switchClass(t.btnPTarget, editor.isActive('paragraph'))
      if (t.hasBtnUlTarget) t.switchClass(t.btnUlTarget, editor.isActive('bulletList'))
      if (t.hasBtnOlTarget) t.switchClass(t.btnOlTarget, editor.isActive('orderedList'))
      if (t.hasBtnColorTarget) t.switchClass(t.btnColorTarget, editor.isActive('textStyle', { color: t.btnColorTarget?.dataset?.value }))

      if (t.hasFormFieldTarget) t.formFieldTarget.value = this.editor.getHTML()
    })
  }

  uploadImage(file) {
    const url = this.element.dataset.imageUploadUrl
    const authenticity_token = this.element.dataset.authenticityToken
    const data = new FormData();
    data.append("authenticity_token", authenticity_token);
    data.append("rte[image_file]", file);
    if (url && authenticity_token) {
      return this.axios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  };

  switchClass(target, isActive) {
    target?.classList?.toggle('btn-light', isActive)
    target?.classList?.toggle('btn-dark', !isActive)
  }

  disconnect() {
    this.element.this.editor.destroy()
  }

  isLoaded() {
    return editor
  }
  isActive(type, opts = {}) {
    return editor.isActive(type, opts)
  }
  toggleHeading(event) {
    const val = event.currentTarget.dataset.value || '0'
    this.editor.chain().toggleHeading({ level: parseInt(val) }).focus().run()
  }
  toggleBold() {
    this.editor.chain().toggleBold().focus().run()
  }
  isValidHttpUrl(string) {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    
    return url.protocol === "http:" || url.protocol === "https:";
  }
  handleLink(e) {
    const currentUrl = this.editor.getAttributes('link').href;
    let providedUrl;
    const urlRel = e.target.dataset.rel;

    if (currentUrl) {
      this.editor.chain().extendMarkRange('link').unsetLink().run();
      return;
    } else {
      providedUrl = window.prompt('URL');
    }

    if (!providedUrl) return;

    if (!/^https?:\/\//i.test(providedUrl)) {
      providedUrl = 'https://' + providedUrl;
    }
    
    if (this.isValidHttpUrl(providedUrl)) {
      this.editor.chain().extendMarkRange('link').setLink({ href: providedUrl, rel: urlRel }).run();
    } else {
      alert("Incorrect URL!");
    }
  }
  toggleItalic() {
    this.editor.chain().toggleItalic().focus().run()
  }
  toggleUnderline() {
    this.editor.chain().toggleUnderline().focus().run()
  }
  toggleStrike() {
    this.editor.chain().toggleStrike().focus().run()
  }
  toggleCode() {
    this.editor.chain().toggleCode().focus().run()
  }
  toggleCodeBlock() {
    this.editor.chain().toggleCodeBlock().focus().run()
  }
  toggleBlockquote() {
    this.editor.chain().toggleBlockquote().focus().run()
  }
  unsetAllMarks() {
    this.editor.chain().unsetAllMarks().focus().run()
  }
  clearNodes() {
    this.editor.chain().clearNodes().focus().run()
  }
  reset() {
    this.unsetAllMarks()
    this.clearNodes()
  }
  setParagraph() {
    this.editor.chain().setParagraph().focus().run()
  }
  toggleBulletList() {
    this.editor.chain().toggleBulletList().focus().run()
  }
  toggleOrderedList() {
    this.editor.chain().toggleOrderedList().focus().run()
  }

  toggleFullScreen(event) {
    this.fullScreenValue = !this.fullScreenValue
    this.wrapperTarget.classList.toggle('tiptap__fullscreen', !!this.fullScreenValue)
    document.body.classList.toggle('tiptap__lockscroll', !!this.fullScreenValue)
    if (this.hasBtnFullScreenTarget) this.switchClass(this.btnFullScreenTarget, !!this.fullScreenValue)
    if (!!this.fullScreenValue == false) this.scrollToEditor()
  }
  scrollToEditor() {
    const section = document.getElementById(this.uid);

    if (section) {
      const rect = section.getBoundingClientRect();
      window.scrollTo({
        top: rect.top + window.scrollY,
      });
    }
  }

  toggleColor(event) {
    const colorValue = event.currentTarget.dataset.value
    const availableColors = ['#FF5733']
    if (availableColors.find((i) => i == colorValue)) {
      const isActive = this.editor.isActive('textStyle', { color: event.currentTarget?.dataset?.value })
      if(isActive) {
        this.editor.commands.unsetColor()
      } else {
        this.editor.chain().setColor(colorValue).focus().run()
      }
    }
  }
  preventEnter(event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault()
    }
  }

  initContent() {
    let content = ''

    if (window.location.hash == "#demorte") {
      content = "<h2>Headline 2</h2><p>The quick brown fox jumps over a lazy dog with <strong>bold (B)</strong></p><h3>Headline 3</h3><p>The quick brown fox jumps over a lazy dog with <em>indent (I)</em></p><h4>Headline 4</h4><p>The quick brown fox jumps over a lazy dog with <u>underline (U)</u></p><h5>Headline 5</h5><p>The quick brown fox jumps over a lazy dog with <s>strike (T)</s></p><p><span>¶</span><strong>Paragraph</strong> of the quick brown fox jumps over a lazy dog, with <code>code</code>.</p><p><strong>&lt;Code&gt;</strong></p><p>Paragraph with <code>code (which is used to define a piece of computer code). Enter</code></p><p><code>will create new paragraph but continue code text style. Soft enter</code><br><code> also continue code text style.</code></p><p><strong>[&lt;Code Block&gt;]</strong></p><pre><code>Paragraph with code block. The whole paragraph changes into code block style. Enter will continue code block text style. But</code></pre><p>soft enter will break out of the code block style and create a new default paragraph.</p><p><strong>Unordered List</strong></p><p>The quick brown fox jumps over a lazy dog</p><ul><li><p>The quick brown fox jumps over a lazy dog</p><ul><li><p>The quick brown fox jumps over a lazy dog</p><ul><li><p>The quick brown fox jumps over a lazy dog</p></li></ul></li></ul></li></ul><p><strong>Ordered List</strong></p><p>The quick brown fox jumps over a lazy dog</p><ol><li><p>The quick brown fox jumps over a lazy dog</p><ol><li><p>The quick brown fox jumps over a lazy dog</p></li><li><p>The quick brown fox jumps over a lazy dog</p><ol><li><p>The quick brown fox jumps over a lazy dog</p></li><li><p>The quick brown fox jumps over a lazy dog</p></li><li><p>The quick brown fox jumps over a lazy dog</p></li></ol></li></ol></li></ol><p><strong>Quote</strong></p><blockquote><p>The quick brown fox jumps over a lazy dog</p></blockquote>"
    } else {
      content = (this.hasFormFieldTarget ? this.formFieldTarget.value : this.tiptapContentEl.innerHTML)
    }

    this.tiptapContentEl.innerHTML = ""
    return content
  }
}
