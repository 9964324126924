import Image from '@tiptap/extension-image'

// Create a custom NodeView for the image
class ImageNodeView {
  constructor(node) {
    this.node = node;
    this.view = node.editor.view;
    this.getPos = node.getPos;
    this.dom = this.createElement();
  }

  // Create the DOM structure for the custom NodeView
  createElement() {
    const { src, alt } = this.node.HTMLAttributes;

    // Wrapper element
    const wrapper = document.createElement('div');
    wrapper.className = 'image';
    if (this.view.state.selection.from === this.getPos()) {
      wrapper.classList.add('ProseMirror-selectednode');
    }
    wrapper.dataset.dragHandle = true;

    // Image element
    const img = document.createElement('img');
    img.src = src;
    img.alt = alt;

    // Alt text indicator
    const altIndicator = document.createElement('span');
    altIndicator.className = 'alt-text-indicator';

    const symbol = document.createElement('span');
    symbol.className = 'symbol material-symbols-outlined';
    symbol.textContent = alt ? 'check' : 'warning';

    const altText = document.createElement('span');
    altText.className = 'text';
    altText.textContent = alt ? `Alt text: "${alt}".` : 'Alt text missing.';

    altIndicator.appendChild(symbol);
    altIndicator.appendChild(altText);

    // Edit button
    const editButton = document.createElement('button');
    editButton.className = 'btn btn-sm btn-outline-secondary px-3 ml-3';
    editButton.type = 'button';
    editButton.textContent = 'Edit';
    editButton.addEventListener('click', this.onEditAlt.bind(this));

    // Append the image to the wrapper
    wrapper.appendChild(img);
    wrapper.appendChild(altIndicator);
    wrapper.appendChild(editButton);

    return wrapper;
  }

  // Edit alt text logic using updateAttributes equivalent
  onEditAlt() {
    const newAlt = prompt('Set alt text:', this.node.HTMLAttributes.alt || '');
    if (newAlt !== null) {
      this.updateAttributes({ alt: newAlt });
    }
  }

  updateAttributes(attributes) {
    const { state, dispatch } = this.view;
    dispatch(
      state.tr.setNodeMarkup(this.getPos(), undefined, {
        ...this.node.HTMLAttributes,
        ...attributes,
      })
    );
  }

  // Update the NodeView when attributes or content change
  update(node) {
    if (node.type !== this.node.type) {
      return false;
    }
    this.node = node;

    const { src, alt } = this.node.HTMLAttributes;

    // Update attributes
    const img = this.dom.querySelector('img');
    img.src = src;
    img.alt = alt;

    // Update alt text indicator
    const symbol = this.dom.querySelector('.alt-text-indicator .symbol');
    const altText = this.dom.querySelector('.alt-text-indicator .text');

    symbol.textContent = alt ? 'check' : 'warning';
    altText.textContent = alt ? `Alt text: "${alt}"` : 'Alt text missing';

    return true;
  }

  // Destroy the NodeView when it is removed
  destroy() {
    this.dom.remove();
  }
}

const CustomImage = Image.extend({
  addNodeView() {
    return (node) => {
      return new ImageNodeView(node);
    };
  },
});

export default CustomImage;
