import { Controller } from "@hotwired/stimulus";
import { Modal, Collapse } from 'bootstrap.native'
import feather from 'feather-icons'
import readmoreConfig from '../config/readmoreConfig'

export default class extends Controller {

  connect() {
  readmoreConfig({ collapsedHeight: 85, linkText: 'All room facilities', className: '.room__row-services-list' });
  }

  showRoomDetails(event) {
    event.preventDefault();

    let roomData = JSON.parse(event.currentTarget.dataset.room)

    new Modal(document.getElementById('roomDetailsModal'), {
      content: this.roomDetailsModalContent(roomData)
    }).show();

    feather.replace();
  }

  roomDetailsModalContent(roomData) {
    return `
      <div class='modal-header border-none pb-0'>
        <button aria-label="Close" class="close black pt-2 pe-2 pb-3"
        data-dismiss="modal" type="button"><i data-feather='x'
        height='32' width='32'></i></button>
      </div>
      <div class='modal-body p-4'>
        <div class='row ms-4'>
          <div class='col-6'>
            <div class='h5'>
              ${roomData.room_grade} ${roomData.room_type}
            </div>
            <p>
            ${roomData.description || ''}
            </p>
            <div>
              <span class='text-gray'>Fit for</span>
              <i class='vertical-align-sub text-primary-light'
              data-feather='user' height='20' width='16'></i>
              ${roomData.capacity} ${(roomData.capacity === 1) ? 'person' : 'people'}
            </div>
            <div>
              <span class='text-gray'>Room size</span>
              <i class='vertical-align-sub text-primary-light'
              data-feather='maximize-2' height='20' width='16'></i>
              ${roomData.area} m2
            </div>
            <hr />
            <div class='room-details-modal-features'>
              <div class='mb-2'><strong>Standard services</strong>
                <div>
                  <div class='badge badge-outline'>Breakfast</div>
                  <div class='badge badge-outline'>Dinner</div>
                  <div class='badge badge-outline'>WiFi</div>
                  <div class='badge badge-outline'>Empty fridge</div>
                </div>
              </div>
              <div class='mb-2'><strong>Additional services</strong>
                <div>
                  <div class='badge badge-outline'>Cold drinks</div>
                </div>
              </div>
              <div class='mb-2'><strong>Room equipement</strong>
                <ul class='list-check'>
                  <li>Cras mattis consectetur purus sit amet fermentum.</li>
                  <li>Vestibulum id ligula porta felis euismod semper.</li>
                  <li>Ipsum Lorem Mattis Tortor Ridiculus</li>
                  <li>Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</li>
                  <li>Dum sociis natoque penatibus et magnis dis montes, mus.</li>
                </ul>
              </div>
              <div><strong>Not included</strong>
                <ul class='list-cross'>
                  <li>Porta Aenean</li>
                  <li>Egestas Consectetur Quam Tristique</li>
                  <li>Ullamcorper Vehicula</li>
                </ul>
              </div>
            </div>
          </div>
          <div class='col-6 d-flex flex-column justify-content-between align-items-end'>
            <div>
              <div class='hotel-details--expanded-image'>
                ${roomData.photos.length ? ('<img id="expandedRoomImage" alt="Room image" src=' + roomData.photos[0] + '>') : ''}
              </div>
              <div class='hotel-details--gallery mt-2 justify-content-center'>
                ${this.roomPhotosGalleryItems(roomData)}
              </div>
            </div>
            <div>
              <a href='#' class='btn btn-big btn-outline-primary'>Check availability
                <i data-feather='calendar' height='19' class='vertical-align-top me-2'></i>
              </a>
              <a href='#' class='btn btn-big btn-primary-contrast'>Let's book!</a>
            </div>
          </div>
        </div>
      </div>

      `

  }

  roomPhotosGalleryItems(roomData) {
    let photos = roomData.photos.map(function(photo) {
      return `
        <div class='hotel-details--gallery-item'>
          <img src="${photo}" alt="Room image" data-action='click->rooms#changeExpandedImage'
          data-target='#expandedRoomImage'>
        </div>
      `
    });
    return photos.join('');
  }

  changeExpandedImage(event) {
    var imageUrl = event.target.src;
    $(event.target.dataset.target)[0].src = imageUrl;
  }
}
