import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['pctField', 'totalAmountField', 'refundVatValue'];
  static values = { baseTotalPrice: Number };

  connect() {
    if (this.hasRefundVatValueTarget) { this.setTaxRate() };
    this.updateSummary();
  }
  
  setTaxRate() {
    this.taxRate = ((this.totalAmountFieldTarget.value * 100) / (this.totalAmountFieldTarget.value - this.refundVatValueTarget.value)).toFixed(2) - 100;
  }
  
  onChangedRefundTotalAmountField() {
    const refundPct = (this.totalAmountFieldTarget.value * 100 / this.baseTotalPriceValue).toFixed(2);
    
    this.pctFieldTarget.setAttribute('value', refundPct);
    this.pctFieldTarget.value = refundPct;
    
    this.updateSummary();
  }
  
  onChangedRefundPctField() {
    const refundValue = ((this.baseTotalPriceValue * this.pctFieldTarget.value) / 100).toFixed(2);

    this.totalAmountFieldTarget.setAttribute('value', refundValue);
    this.totalAmountFieldTarget.value = refundValue;
    
    this.updateSummary();
  }
  
  updateSummary() {
    const sum = Array.from(document.querySelectorAll('[data-refund-target="totalAmountField"]')).reduce((acc, item) => {
      return acc + parseFloat(item.value);
    }, 0);
    document.querySelector('#total-value').value = sum.toFixed(2);
  }

  recalculateVat() {
    if (this.hasRefundVatValueTarget && this.refundVatValueTarget.value > 0) {
      const netPrice = (this.totalAmountFieldTarget.value / (1 + (this.taxRate / 100))).toFixed(2);
      this.refundVatValueTarget.value = Math.round((this.totalAmountFieldTarget.value - netPrice) * 100) / 100;
    }
  }
}
