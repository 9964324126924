import { Controller } from "@hotwired/stimulus";
import initializeLeafletMap from '../config/leafletConfig'
import axios from 'axios';
import markerShadowImage from 'leaflet/dist/images/marker-shadow.png';
import markerIconImage from 'leaflet/dist/images/marker-icon.png';

export default class extends Controller {
  static targets = [
    'hotelsMap',
    'mapData',
    'toggleMap'
  ]

  initialize() {
    this.zoom = parseInt(this.hotelsMapTarget.dataset.zoom) || 13;
  }

  connect() {
    this.getHotels().then(()=>{
      this.addMap();
      if (this.hotels.length) {
        this.addMarkers();
      }

      if (!$(this.hotelsMapTarget).hasClass('visible')) {
        $(this.hotelsMapTarget).hide();
      }
    });
  }

  toggleMap(event) {
    event.preventDefault();
    $(this.hotelsMapTarget).toggle(1000);
  }

  addMap() {
    this.map = initializeLeafletMap(
      {
        tilerKey: this.data.get('tiler'),
        mapTargetId: this.hotelsMapTarget.id,
        viewCoordinates: this.viewCoordinates,
        zoom: this.zoom
      }
    );
  }

  addMarkers() {
    let icon = { icon: L.icon(this.iconConfig) };
    var self = this;

    console.log(this.hotels);

    this.hotels.forEach(function (hotel, _index) {
      let marker = L.marker([hotel.latitude, hotel.longitude], icon)
                    .addTo(this.map);

      marker.bindPopup(() => {
        var el = document.createElement('div');

        axios.get(`/api/hotels/${hotel.slug}.json`)
             .then(({data}) => {
               el.innerHTML = this.popupTemplate(data);
              });

        return el;
      }, { minWidth: 250 });
    }, this);
  }

  get averageLatitude() {
    if (this.hotels.length) {
      return this.hotels.reduce((total, next) => total + next.latitude, 0) / this.hotels.length;
    } else {
      return 35.652832;
    }
  }

  get averageLongitude() {
    if (this.hotels.length) {
      return this.hotels.reduce((total, next) => total + next.longitude, 0) / this.hotels.length;
    } else {
      return 139.839478;
    }
  }

  get viewCoordinates() {
    return [this.averageLatitude, this.averageLongitude];
  }

  async getHotels() {
    let mapData;

    try {
      mapData = JSON.parse(this.mapDataTarget.dataset.mapData);
    } catch {
      mapData = { type: 'inline', data: [] }
    }

    this.hotels = mapData.type === 'inline' ? mapData.data : await this.fetchHotelsList(mapData.data);
  }

  popupTemplate(hotel) {
    return(`
      <div class='row hotel-map-popup'>
        <div class='col-6'>
          <img src='${hotel.image_url}' alt='${hotel.name}'></img>
        </div>
        <div class='col-6'>
          <p><strong>${hotel.name}</strong></p>
          <p>${hotel.address}</p>
          <a href=/hotels/${hotel.slug}>All rooms</a>
        </div>
      </div>
    `)
  }

  async fetchHotelsList(endpointUrl) {
    let hotels;
    await axios.get(endpointUrl)
      .then(({ data }) => hotels = data)
      .catch(() => hotels = []);

    return hotels;
  }

  get iconConfig() {
    return {
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: markerIconImage,
      shadowUrl: markerShadowImage
    }
  }
}
